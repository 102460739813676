import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { ZoomMtg } from '@zoom/meetingsdk';

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();



@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss']
})
export class ZoomComponent implements OnInit {
  logo = '';
 
  zoom_psw = '';
  zoom_id = '';
  user: any = {};
 
  error = '';




  sdkKey = 'yZQT9MyKcN83L3sQ2LgiTmZTZ18563wq9WJX'
  meetingNumber = ''
  passWord = ''
  role = 0
  userName = ''
  userEmail = ''
  registrantToken = ''
  zakToken = ''
  leaveUrl = ''


 
  constructor (
    private http: HttpClient,
    private ngZone: NgZone,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {

    this.sdkKey=environment.zoomSdkKey;
    let token = this.route.snapshot.paramMap.get('token')
    this.http.post( environment.apiUrl + 'zoomsignature/' + token, {token: token} ).subscribe( (data: any) => {

      // update page info tags
      let favIcon: HTMLLinkElement = document.querySelector('link[rel="icon"]');
      favIcon.href = data.company.favicon;
      let title: HTMLTitleElement = document.querySelector('title');
      title.textContent = data.event.title + ' - ' + data.company.name;

      this.logo = data.company.logo;

      this.leaveUrl = ( document.location.protocol === 'https:' ? 'https://' : 'http://' ) + data.company.domain + '/events/profile/' + data.event.id;
//console.log('data.company ', data.company )
      this.userName = data.user.name;
     // this.userEmail = data.user.name;
      

      const url = new URL(data.event.zoom_link);
      const urlParams = url.searchParams
      var u=url.pathname;
      var psw = urlParams.get('pwd');
      var id = u.substring(u.lastIndexOf('/') + 1);
      this.zoom_id=id;
      this.zoom_psw=psw;

      // loads language files, also passes any error messages to the ui
      ZoomMtg.i18n.load('es-ES');
      ZoomMtg.i18n.reload('es-ES');

      this.startMeeting(data.signature)
    },
    error => {
      console.log(error.error);
      this.error = error.error.message
      document.getElementById('zmmtg-root').style.display = 'none'
    });
  }


  startMeeting(signature) {
    console.log('firma',signature)
    document.getElementById('zmmtg-root').style.display = 'block'

    this.ngZone.runOutsideAngular(() => {
      ZoomMtg.init({
        leaveUrl: this.leaveUrl,
        patchJsMedia: true,
        leaveOnPageUnload: true,
        success: (success) => {
          console.log(success)
          ZoomMtg.join({
            signature: signature.original.signature,
            sdkKey: this.sdkKey,
            meetingNumber: this.zoom_id,
            passWord: this.zoom_psw,
            userName: this.userName,
            userEmail: this.userEmail,
            tk: this.registrantToken,
            zak: this.zakToken,
            success: (success) => {
              console.log(success)
            },
            error: (error) => {
              console.log(error)
            }
          })
        },
        error: (error) => {
          console.log(error)
        }
      })
    })
  }

  startMeeting1(signature) {
    // Mostrar el contenedor de la reunión de Zoom
    document.getElementById('zmmtg-root').style.display = 'block';
    let payload={
      meetingNumber:'84388999064',
      role:'1',
      sdkKey:'yZQT9MyKcN83L3sQ2LgiTmZTZ18563wq9WJX',
      sdkSecret:'rYnGtACTBTS5fuK94TQt7tbL3PpXSZZNP2X3',
      leaveUrl:'http://localhost:4200',
      
      
    }
    // Inicializar la reunión
    ZoomMtg.generateSDKSignature({
      meetingNumber:payload.meetingNumber,
      role:payload.role,
      sdkKey:payload.sdkKey,
      sdkSecret:payload.sdkSecret,
      success:function(signature:any){
        console.log('signature',signature)

        ZoomMtg.init({
          leaveUrl: payload.leaveUrl,
          success: (initSuccess) => {
            console.log('Init Success ', initSuccess);
      
            // Unirse a la reunión
            ZoomMtg.join({
              meetingNumber: payload.meetingNumber,
              passWord: '123456', // Opcional
              sdkKey: payload.sdkKey,
              userName: 'this.user.name',
              userEmail: '', // Opcional
              signature: signature.result,
              tk:'',

              success: (data) => {
                console.log('Join Success ', data);
              },
              error: (joinError) => {
                console.error('Join Error ', joinError);
                document.getElementById('zmmtg-root').style.display = 'none';
              }
            });
          },
          error: (initError) => {
            console.error('Init Error ', initError);
            document.getElementById('zmmtg-root').style.display = 'none';
          }
        });


      }
      
      
    })
    
  }


  startMeetingOld(signature) {

    document.getElementById('zmmtg-root').style.display = 'block'

    // ZoomMtg.init({
    //   leaveUrl: this.leaveUrl,
    //   isSupportAV: true,

    //   success: (success) => {
    //     console.log(success)
    //     ZoomMtg.join({
    //       signature: signature,
    //       meetingNumber: this.zoom_id,
    //       userName: this.user.name,
    //       apiKey: this.zoom_apiKey,
    //       userEmail:'',
    //       passWord: this.zoom_psw,

    //       success: (success) => {
    //         console.log(success)
    //       },
    //       error: (error) => {
    //         console.log(error)
    //         document.getElementById('zmmtg-root').style.display = 'none'
    //       }
    //     })

    //   },
    //   error: (error) => {
    //     console.log(error)
    //     document.getElementById('zmmtg-root').style.display = 'none'
    //   }
    // })
  }
}
